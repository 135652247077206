<template>
	<v-snackbar :absolute="true" :multi-line="true" bottom right :timeout="0" :value="true">
		<v-layout align-center column justify-center>
			<v-flex>{{ message }}</v-flex>
			<v-progress-linear v-model="progressValue" :indeterminate="indeterminate" color="secondary"></v-progress-linear>
		</v-layout>
	</v-snackbar>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'MobileAppsProgress',
	mixins: [SuperAdminModuleGuard],
	props: {
		progress: {
			required: true,
			type: Number
		},
		message: {
			required: true,
			type: String
		},
		indeterminate: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		progressValue: function () {
			return this.indeterminate ? null : this.progress
		}
	}
}
</script>
